import React from "react"
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"

import SEO from "components/seo"
import LinkToPage from "components/common/LinkToPage"
import LogoHorizontal from "assets/images/logo-fullcolor.svg"
import CogPattern from "assets/images/patterns/floating-cogs.svg"
import ArrowLabel from "assets/images/feather-icons/arrow-red.svg"
import {
  ContentWrapper,
  LogoWrapper,
  CopyrightFooter,
  ImageWrapper,
} from "assets/styles/404styles"

const NotFoundPage = ({ intl }) => (
  <>
    <SEO title={`404: ${intl.formatMessage({ id: "title" })}`} />
    <ContentWrapper>
      <Link to="/">
        <LogoWrapper>
          <img src={LogoHorizontal} alt="company logo horizontal" />
        </LogoWrapper>
      </Link>
      <article>
        <h1>
          <FormattedMessage id="notfound.header" />
        </h1>
        <p>
          <FormattedMessage id="notfound.description" />
        </p>
        <LinkToPage>
          <Link to="/">
            <FormattedMessage id="go_back" />
            <img src={ArrowLabel} alt="arrow pointing right" />
          </Link>
        </LinkToPage>
      </article>
      <CopyrightFooter>© Copyright Elpis 2020</CopyrightFooter>
    </ContentWrapper>
    <ImageWrapper
      style={{ backgroundImage: `url(${CogPattern})` }}
    ></ImageWrapper>
  </>
)

export default injectIntl(NotFoundPage)
