import styled from "styled-components"
import { DEVICES } from "src/constants"

export const ContentWrapper = styled.div`
  height: 100vh;
  padding: 40px;
  @media ${DEVICES.laptopL} {
    padding-left: 100px;
    padding-top: 100px;
    width: 55%;
  }
  article {
    img {
      margin-left: 5px;
    }
    h1 {
      margin-bottom: 30px;
    }
    p {
      margin-bottom: 30px;
      max-width: 450px;
      @media ${DEVICES.laptopL} {
        max-width: 500px;
      }
    }
  }
`
export const LogoWrapper = styled.div`
  width: 155px;
  margin-bottom: 30%;
  img {
    height: auto;
    width: 100%;
  }
`

export const CopyrightFooter = styled.span`
  bottom: 45px;
  color: var(--color-text_footer);
  font-size: 14px;
  font-weight: 600;
  left: 40px;
  position: absolute;
  @media ${DEVICES.laptopL} {
    left: 100px;
  }
`

export const ImageWrapper = styled.div`
  background-color: var(--color-bg-secondary);
  display: none;
  height: 100vh;
  position: absolute;
  right: 0;
  top: 0;
  width: 45%;
  @media ${DEVICES.laptop} {
    display: block;
  }
`
